import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/Sidebar/Sidebar'
import Navbar from '../../components/Navbar/Navbar'
import "./EachTeacherPage.scss"; 
import 'react-toastify/dist/ReactToastify.css';
import { Button, Paper } from '@mui/material';
import ChapterForm from './ChapterForm';
import EachTeacherTaskDetails from './EachTeacherTaskDetails';
import { useFetchAllTeacherChapter } from '../../hooks/useFetchAllTeacherChapter';
import { useParams } from 'react-router-dom';
import { getIndianDate } from '../../config';

// const chapters = [
//   {
//     chapter_name: "Atomic particle",
//     chapter_no: 1,
//     all_task_completed: 1,
//     score_obtained: 80,
//     total_marks: 100,
//     completed_on: "2023-11-01",
//     deadline: "2023-11-02"
//   },
//   {
//     chapter_name: "Nano particle",
//     chapter_no: 2,
//     all_task_completed: 0,
//     score_obtained: 20,
//     total_marks: 100,
//     deadline: "2023-11-14"
//   },
//   {
//     chapter_name: "Chemical Reactions",
//     chapter_no: 3,
//     all_task_completed: 0,
//     score_obtained: 0,
//     total_marks: 100,
//     deadline: "2023-12-05"
//   },
//   {
//     chapter_name: "Organic Chemistry",
//     chapter_no: 4,
//     all_task_completed: 0,
//     score_obtained: 0,
//     total_marks: 100,
//     deadline: "2023-12-16"
//   }
// ];

const EachTeacherPage = () => {
  const {teacherId: teacher_id} = useParams();
  const [chaptersDetails, setChapterDetails] = useState([]);
  const [open, setOpen] = useState(false);
  const [chapterOpen, setChapterOpen] = useState(false);
  const [selectedChapter, setSelectedChapter] = useState({});
  
  const { isLoading: allChapterLoading , data: teacherChapterDetails } = useFetchAllTeacherChapter({teacher_id});

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleChapterClose = () => {
    setChapterOpen(false);
  };

  const handleChapterOpen = (chapter) => {
    setSelectedChapter(chapter);
    setChapterOpen(true);
  };

  useEffect(() => {

    if(!allChapterLoading) { 
      let chapters = teacherChapterDetails?.data?.chapterDetails;
      let allChapters = [];
      for(let i = 0; i < chapters?.length; i++){
        if(chapters[i].all_task_completed == 1){
          chapters[i].status = 'Completed';
          // chapters[i].backgroundColor = '#009c2c';
          chapters[i].color = 'green';
        }else if(i == 0 && chapters[i].all_task_completed == 0){

          chapters[i].status = 'OnGoing';
          // chapters[i].backgroundColor = '#C7DCFC';
          chapters[i].color = 'orange';
        }else{
          let prevDeadline = new Date(chapters[i-1].deadline);
          let currentDate = new Date();
          if(chapters[i-1].all_task_completed == 1 || (prevDeadline < currentDate)){
            chapters[i].status = 'OnGoing';
            // chapters[i].backgroundColor = '#C7DCFC';
            chapters[i].color = 'orange';
          }else{
            chapters[i].status = 'NotStarted';
            // chapters[i].backgroundColor = '#E4E4E4';
            chapters[i].color = 'red';
          }
        }
        allChapters.push(chapters[i]);
      }
      setChapterDetails(allChapters);
    }
  }, [teacherChapterDetails, allChapterLoading]);
 

  return (
    <div className='teachertask-container'>
      <Sidebar />
      <div className='teacherTask'>
        <Navbar />
        <div style={{ width: "100%", marginTop: "10px" }}>
          <Button variant='contained'
            style={{
              display: "flex",
              margin: "auto",
              padding: "20px",
              width: "fit-content",
              color: "white",
              backgroundColor: "#1377C0"
            }}
            onClick={handleOpen}
          >Create Chapter</Button>
        </div>
        <div style={{ marginLeft: "5%", width: "100%" }}>
        { chaptersDetails.length > 0 && <h2>All Chapters</h2> }
        {
          chaptersDetails.length > 0 && chaptersDetails.map((chapter) => ( 
              <div style={{ width: "90%" }}>
                <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px'}}>
                  <div className="chapter-details">
                    <p className='first-para'>{chapter.chapter_name}</p>
                    {chapter.status == 'Completed' && <p style={{ color: chapter.color}}>Completed on : {getIndianDate(chapter.completed_on)}</p>}
                    {chapter.status == 'OnGoing' && <p style={{ color: chapter.color}}>Ongoing</p>}
                    {chapter.status == 'NotStarted' && <p style={{ color: chapter.color}}>Not yet started</p>}
                  </div>
                  <div className="chapter-details">
                    {<p className='first-para'>{chapter.status == 'Completed' && <>Score: {chapter.mark_obtained}/{chapter.total_marks}</>}</p>}
                    { chapter.status != 'NotStarted'
                      &&
                      <Button variant='contained'
                        style={{ 
                          marginTop: "auto",
                          marginBottom: "auto",
                          padding: "16px",
                          width: "fit-content",
                          color: "white",
                          backgroundColor: "#1377C0"
                        }}
                        onClick={() => handleChapterOpen(chapter)}
                    >Details</Button>
                    }
                  </div>
                </Paper>
              </div>
          ))
        }
        </div>
      </div>
      <ChapterForm open={open} handleClose={handleClose} chapterNo={chaptersDetails?.length + 1}/>
      <EachTeacherTaskDetails open={chapterOpen} onClose={handleChapterClose} chapter={selectedChapter} />
    </div >
  )
}

export default EachTeacherPage;