import { useState, useEffect } from "react";
import "./Dash.scss";
import student1 from "../../assest/male.png";
import teacher from "../../assest/professor.png";
import Courses from "../../assest/courses.png";
import Medium from "../../assest/medium.png";
import ClassImage from "../../assest/library.png";
import jwt_decode from "jwt-decode";
import axios from "axios";
import Widgest from "../Widgest/widgest";
import InfoContainer from "../InfoContainer/InfoContainer";
import SubjectList from "../../components/SubjectLIst/Subject";

import { GW_URL } from "../../config";
import { CircularProgress } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import DataLoader from "../Loader/DataLoader";
import ListWidgest from "../Widgest/ListWidgest";
import { adminConfig } from "../../config";
import { ToastContainer, toast } from 'react-toastify';
import BoardImage from "../../assest/board.png"
import { useFetchCourse } from "../../hooks/useGetAllCourse";


const Dashboard = (props) => {
  let decodeToken = jwt_decode(localStorage.getItem("auth_token"));
  let school_id =
    localStorage.getItem("superadmin_school") === null
      ? decodeToken.result.school_id
      : localStorage.getItem("superadmin_school");



      console.log(adminConfig);

  const { data: schoolData, isLoading: schoolLoading } = useQuery(
    ["school", school_id],
    () =>
      axios
        .get(`${GW_URL}/schools/${school_id}`, adminConfig)
        .then((res) => {
          if (decodeToken.result.role === "superAdmin")
            props.AdminNameHandler("SuperAdmin");
          else props.AdminNameHandler(res?.data.schoolDetail.admin_name);
          return res?.data;
        })
  );


  const { data: courseIdData, isLoading: courseIdLoading } = useQuery(
    ["courseId", school_id],
    () =>
      axios
        .get(`${GW_URL}/schools/${school_id}/getCourseId`, adminConfig)
        .then((res) => res?.data?.course_name)
  );
  // const {data:courseIdData,isLoading:courseIdLoading}=useQuery(school_id);
  

  
    
      // const { data: courseIdData, isLoading: courseIdLoading } = useQuery(
      //   ['courseId', school_id],
      //   () =>
      //     axios.get(`${GW_URL}/schools/${school_id}/getCourseId`, adminConfig).then(res => res?.data?.course_name)
      // );
    
      


 

  const { data: mediumIdData, isLoading: mediumIdLoading } = useQuery(
    ["mediumId", school_id],
    () =>
      axios
        .get(`${GW_URL}/schools/${school_id}/getMediumId`, adminConfig)
        .then((res) => res?.data?.medium_name)
  );

  const { data: boardIdData, isLoading: boardIdLoading } = useQuery(
    ["boardId", school_id],
    () =>
      axios
        .get(`${GW_URL}/schools/${school_id}/getBoardId`, adminConfig)
        .then((res) => res?.data?.board_name)
  );

  const { data: classIdData, isLoading: classIdLoading } = useQuery(
    ["classId", school_id],
    () =>
      axios
        .get(`${GW_URL}/schools/${school_id}/getClassId`, adminConfig)
        .then((res) => {
          const len = res?.data?.class_name.length;
          localStorage.setItem(
            "school-highest-class",
            res.data.class_name[len - 1]
          );
          console.log(res?.data?.class_name[0], res?.data?.class_name[len - 1]);
          if (res?.data?.class_name[0] == res?.data?.class_name[len - 1]) {
            return res?.data?.class_name[0];
          }
          return (
            res.data.class_name[0] +
            " " +
            " to " +
            " " +
            res.data.class_name[len - 1]
          );
        })
  );

  return (
    <div>
      <div className="dash">
        {schoolLoading ? (
          <div className="top">
            <DataLoader width={100} Loading={schoolLoading} />
          </div>
        ) : (
          <InfoContainer data={schoolData?.schoolDetail} />
        )}

        <div className="bottom">
          <Widgest
            name={"Student"}
            count={schoolData?.totalStudent}
            image={student1}
            Loader={schoolLoading}
          />
          <Widgest
            name={"Teacher"}
            count={schoolData?.totalTeacher}
            image={teacher}
            Loader={schoolLoading}
          />
          <Widgest
            name={"Classes"}
            count={classIdData}
            image={ClassImage}
            Loader={classIdLoading}
          />
          <ListWidgest
            name={"Board"}
            count={boardIdData}
            image={BoardImage}
            Loader={boardIdLoading}
          />

          <ListWidgest
            name={"Medium"}
            count={mediumIdData}
            image={Medium}
            Loader={mediumIdLoading}
          />
          <ListWidgest
            name={"Courses"}
            count={courseIdData}
            image={Courses}
            Loader={courseIdLoading}
          />
        </div>
        <SubjectList />
        <ToastContainer/>
      </div>
    </div>
  );
};

export default Dashboard;
