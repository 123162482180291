import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { GW_URL, adminConfig } from "../config";

export const ALL_HOLIDAY_KEY = 'curriculum-list';
 
const useFetchAllHoliday = (year) => {
    return useQuery(
        [ALL_HOLIDAY_KEY , year],
        () => axios.get(`${GW_URL}/getHolidays?year=${year}`, adminConfig).
        then(res => res?.data?.holidayList)
    );
};

export default useFetchAllHoliday;