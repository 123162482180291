import React, { useState, useEffect } from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    Checkbox,
    ListItemText,
    CircularProgress,
} from '@mui/material';
import { GW_URL, adminConfig, getAdminDetails } from '../../config';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

const initialValues = {
    class_id: '',
    medium_section: [{ medium: '', sections: [] }],
};

const ADMIN_CLASS_DETAILS = ''

const ChangeFormatOfDropDownValue = (arr1, arr2) => {
    const tempArray = [];
    for (let i = 0; i < arr1.length; i++) {
        tempArray.push({
            id: arr1[i],
            value: arr2[i],
            label: arr2[i],
        });
    }
    return tempArray;
};

const addClassDetails = ({ teacher_id, class_id, medium_section }) => {
    return axios.post(`${GW_URL}/teacher/${teacher_id}/addTeacherClassAndMedium`, { class_id, medium_section }, adminConfig)
}

const AddTeacherClass = ({ teacher_id, open, setOpen }) => {
    const [formValues, setFormValues] = useState(initialValues);
    const [classOptions, setClassOptions] = useState([]);
    const [loadingClasses, setLoadingClasses] = useState(true);

    const { school_id } = getAdminDetails();

    const { data: Mediumarray, isLoading: mediumLaoding, isError: mediumError, error: mediumErrorMsg } = useQuery(
        {
            queryKey: ['medium-list', school_id],
            queryFn: () => {
                return axios.get(`${GW_URL}/schools/${school_id}/getMediumId`, adminConfig);
            },
        }
    );
    const MediumArray = Mediumarray?.data ? ChangeFormatOfDropDownValue(Mediumarray?.data.medium_id, Mediumarray?.data.medium_name) : [];

    const { data: classesList, isLoading: classesListLoading, isError: classesListError } = useQuery({
        queryKey: ['class-list', school_id],
        queryFn: () => {
            return axios.get(`${GW_URL}/schools/${school_id}/getClassId`, adminConfig);
        },
    });

    const queryClient = useQueryClient();
    const mutation = useMutation({
        mutationFn: addClassDetails,
        onSuccess: () => {
            queryClient.invalidateQueries('class-list');
            setFormValues(initialValues);
            setClassOptions([]);
            toast.success("Class details Added", {
                position: "top-center",
                autoClose: 2000,
                theme: "dark",
            });
            handleClose();
        },
        onError: () => {
            toast.error("Something went wrong!",
            {
                position: "top-center",
                autoClose: 2000,
                theme: "dark",
            });
        }
    });

    useEffect(() => {
        const fetchClassOptions = async () => {
            if (!classesListLoading) {
                const allClasses = classesList?.data?.class_id.map((class_id, index) => ({
                    class_id,
                    class_name: classesList?.data?.class_name[index],
                }));
                setClassOptions(allClasses);
                setLoadingClasses(false);
            }
        };

        fetchClassOptions();
    }, [classesListLoading, classesList]);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setFormValues(initialValues);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleMediumChange = (index, event) => {
        const { value } = event.target;
        const updatedMediumSection = [...formValues.medium_section];
        updatedMediumSection[index].medium = value;
        updatedMediumSection[index].sections = [];
        setFormValues({ ...formValues, medium_section: updatedMediumSection });
    };

    const handleSectionChange = (index, event) => {
        const { value } = event.target;
        const updatedMediumSection = [...formValues.medium_section];
        updatedMediumSection[index].sections = value;
        setFormValues({ ...formValues, medium_section: updatedMediumSection });
    };

    const handleAddMediumSection = () => {
        setFormValues({
            ...formValues,
            medium_section: [...formValues.medium_section, { medium: '', sections: [] }],
        });
    };

    const handleRemoveMediumSection = (index) => {
        const updatedMediumSection = [...formValues.medium_section];
        updatedMediumSection.splice(index, 1);
        setFormValues({ ...formValues, medium_section: updatedMediumSection });
    };

    const handleSubmit = (e) => {
        e.preventDefault(); 
        if (formValues.class_id.length == 0) {
            toast.error("Class is required!",
                {
                    position: "top-center",
                    autoClose: 2000,
                    theme: "dark",
                });
            return;
        }
        if(formValues.medium_section[0].medium.length == 0 || formValues.medium_section[0].sections.length == 0){
            toast.error("Medium is required!",
                {
                    position: "top-center",
                    autoClose: 2000,
                    theme: "dark",
                });
            return;
        } 
        mutation.mutate({teacher_id, class_id: formValues.class_id, medium_section: formValues.medium_section});
    };

    const buttonStyle = {
        marginTop: '16px',
        width: '150px', // Adjust the width as needed
        alignSelf: 'flex-start',
    };

    const removeButtonStyle = {
        marginTop: '16px',
        width: '150px', // Adjust the width as needed
        alignSelf: 'flex-end',
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
            <DialogTitle>Add Class</DialogTitle>
            <DialogContent>
                <FormControl fullWidth variant="outlined" style={{ marginBottom: '16px', marginTop: '16px' }}>
                    <InputLabel id="class-id-label">Class</InputLabel>
                    <Select
                        labelId="class-id-label"
                        name="class_id"
                        value={formValues.class_id}
                        onChange={handleInputChange}
                        required
                        displayEmpty
                        inputProps={{ name: 'class_id', id: 'class-id' }}
                    >
                        {loadingClasses ? (
                            <MenuItem disabled>
                                <CircularProgress size={20} />
                            </MenuItem>
                        ) : (
                            classOptions.map((classOption) => (
                                <MenuItem key={classOption.class_id} value={classOption.class_id}>
                                    {classOption.class_name}
                                </MenuItem>
                            ))
                        )}
                    </Select>
                </FormControl>

                {formValues.medium_section.map((mediumSection, index) => (
                    <div key={index} style={{ display: 'flex', flexDirection: 'row', gap: '16px', marginBottom: '16px' }}>
                        <FormControl fullWidth variant="outlined" style={{ flexGrow: 1 }}>
                            <InputLabel id={`medium-label-${index}`}>Medium</InputLabel>
                            <Select
                                labelId={`medium-label-${index}`}
                                name={`medium-${index}`}
                                value={mediumSection.medium}
                                onChange={(event) => handleMediumChange(index, event)}
                                required
                            >
                                {MediumArray.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth variant="outlined" style={{ flexGrow: 1 }}>
                            <InputLabel id={`section-label-${index}`}>Sections</InputLabel>
                            <Select
                                labelId={`section-label-${index}`}
                                name={`section-${index}`}
                                value={mediumSection.sections}
                                onChange={(event) => handleSectionChange(index, event)}
                                multiple
                                renderValue={(selected) => selected.join(', ')}
                                required
                            >
                                {/* Replace the following MenuItem items with your actual section options */}
                                <MenuItem value="A">
                                    <Checkbox checked={mediumSection.sections.includes('A')} />
                                    <ListItemText primary="A" />
                                </MenuItem>
                                <MenuItem value="B">
                                    <Checkbox checked={mediumSection.sections.includes('B')} />
                                    <ListItemText primary="B" />
                                </MenuItem>
                                {/* Add more section options as needed */}
                            </Select>
                        </FormControl>
                        {index > 0 && (
                            <Button
                                variant="outlined"
                                color="error"
                                onClick={() => handleRemoveMediumSection(index)}
                                style={removeButtonStyle}
                            >
                                Remove
                            </Button>
                        )}
                    </div>
                ))}
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddMediumSection}
                    style={buttonStyle}
                >
                    Add More
                </Button>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="outlined" style={{ color: '#2196F3' }}>
                    Cancel
                </Button>
                <Button onClick={handleSubmit} variant="contained" color="primary">
                    Submit
                </Button>
            </DialogActions>
        <ToastContainer />
        </Dialog>
    );
};

export default AddTeacherClass;